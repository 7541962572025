body {
  position: relative;
}
.container-main {
  height: 100vh;
}

.body-default {
  background: #cee9f8;
}

.card-search {
  border-radius: 40px;
  height: 450px;
}

.searchNav {
  background: white;
  width: 28rem;
  border-radius: 60px;
  z-index: 99;
}

.result-card {
  /* max-width: 64em; */
  /* width: 100%; */
  /* max-height: 10em; */
  /* height: 10em; */
  border-radius: 38px;
}

.profile-pic {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.available-date {
  width: 90px;
  height: 30px;
  border-radius: 10px;
}

.available-date-default-border {
  border: 1px solid #5cb5e9;
}

.select-date {
  width: 40px;
  height: 40px;
}

.select-date-default {
  background: #5cb5e9;
}

.available > div > .available-date.active {
  border: none;
  box-shadow: none;
}

.available > div > .available-date-default.active {
  background-color: #ffc43d !important;
}

.available-date.active small {
  color: white !important;
}

.nav-pills .nav-link {
  border-radius: 60px;
  color: #c4c4c4;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: #fde408;
  color: black !important;
}

.react-calendar {
  width: auto !important;
}

.spinner-grow {
  width: 100px;
  height: 100px;
}

footer p {
  width: 88vw !important;
  border-radius: 40px 40px 0 0;
  height: 80px;
  bottom: 0px;
  /* top: 0px; */
  /* background-color: #ffc43d !important; */
}

.form-control:focus,
.form-select:focus {
  box-sizing: border-box;
  border: 1px transparent black;
  box-shadow: none;
}

@media screen and (max-width: 768px) {
  .card-title {
    border: none !important;
    font-size: 35px;
    text-align: center;
  }

  .card-sub-title {
    text-align: center;
  }

  .card-search {
    height: unset;
  }

  .select-specialty {
    border-radius: unset !important;
    border-radius: 6px !important;
  }
}

@media screen and (max-width: 575px) {
  .search-location {
    top: 1px;
    right: 13.2px;
    border-radius: unset !important;
    height: 3.5em;
  }

  .select-practice {
    border-radius: unset !important;
    border-radius: 6px !important;
  }

  .searchNav {
    width: auto;
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .card-texts {
    padding-top: 60px;
  }

  .available-date {
    width: 250px;
    height: 30px;
  }
}

@media screen and (max-width: 768px) {
  /* .result-card {
    width: 70em;
    max-width: unset;
  } */
}
